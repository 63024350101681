export enum SourceIdentityRecordCategory {
  NameWithBadCharacters = 'nameWithBadCharacters',
  FirstNameLastNameEqual = 'firstNameLastNameEqual',
  NameUnrecognized = 'nameUnrecognized',
  MissingNameDerivable = 'missingNameDerivable',
  MultipleNames = 'multipleNames',
  InitialsOnly = 'initialsOnly',
  nameIsRepeated = 'nameIsRepeated',
  EmailDomainMisspelled = 'emailDomainMisspelled',
  EmailInvalid = 'emailInvalid',
  EmailOnly = 'emailOnly',
  PhoneInvalid = 'phoneInvalid',
  PlaceholderData = 'placeholderData',
  TestData = 'testData',
  SpamData = 'spamData',
  MaliciousData = 'maliciousData',
  Business = 'business',
  Household = 'household',
  Duplicates = 'duplicates',
  DateOfBirthInvalid = 'dateOfBirthInvalid',
  AddressInvalid = 'addressInvalid',
  SuffixInName = 'suffixInName',
}

export function recordCategoryToLabel(category: SourceIdentityRecordCategory) {
  switch (category) {
    // Name issues
    case SourceIdentityRecordCategory.NameWithBadCharacters:
      return 'Special characters detected in a name fields';
    case SourceIdentityRecordCategory.FirstNameLastNameEqual:
      return 'First and last names values are the same';
    case SourceIdentityRecordCategory.NameUnrecognized:
      return 'Unrecognized value detected in a name field';
    case SourceIdentityRecordCategory.MissingNameDerivable:
      return 'Name field is questionable or empty, but can potentially be derived from the email address';
    case SourceIdentityRecordCategory.MultipleNames:
      return 'Both first and last name detected in a single field';
    case SourceIdentityRecordCategory.InitialsOnly:
      return 'At least one name field contains only an initial';
    case SourceIdentityRecordCategory.nameIsRepeated:
      return 'Some portion of the first or last name value is repeated in both fields';
    case SourceIdentityRecordCategory.SuffixInName:
      return 'Suffix is in a name field';
    // Email
    case SourceIdentityRecordCategory.EmailDomainMisspelled:
      return 'Common misspelling detected in an email address';
    case SourceIdentityRecordCategory.EmailInvalid:
      return 'Invalid email address does not exist at the mail server';
    case SourceIdentityRecordCategory.EmailOnly:
      return 'Data record contains only an email address';
    // Phone
    case SourceIdentityRecordCategory.PhoneInvalid:
      return 'Invalid phone number detected in incorrect format or does not exist';
    // Non consumer records
    case SourceIdentityRecordCategory.PlaceholderData:
      return 'Placeholder consumer data detected';
    case SourceIdentityRecordCategory.TestData:
      return 'Employee entered test data patterns detected';
    case SourceIdentityRecordCategory.SpamData:
      return 'Robot or spam data detected';
    case SourceIdentityRecordCategory.MaliciousData:
      return 'Malicious data patterns detected';
    //Business
    case SourceIdentityRecordCategory.Business:
      return 'Name field values appear to represent a business';
    //Households
    case SourceIdentityRecordCategory.Household:
      return 'Name field values appear to represent more than one consumer';
    // Duplicates
    case SourceIdentityRecordCategory.Duplicates:
      return 'Multiple data records detected that represent the same consumer identity';
    // Date of Birth
    case SourceIdentityRecordCategory.DateOfBirthInvalid:
      return 'Invalid date of birth detected in the remote past or future';
    case SourceIdentityRecordCategory.AddressInvalid:
      return 'Unrecognized address detected';
    default:
      return category;
  }
}
